@tailwind base;
@tailwind components;
@tailwind utilities;

html{
  display: flex;
  justify-content: center;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #0B0C19;
  max-width: 1800px;
  justify-content: center;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Blanka-Regular';
  /* src\fonts\Blanka-Regular.otf */
  src: url('./fonts/Blanka-Regular.otf') format('opentype');
  /* Add other font file formats if you have them */
  font-weight: normal;
  font-style: normal;
}



.my-leaf{
  left: -156px;
  scale: 1.5;
}


.my-leaf2{
  right: -128px;
  scale: 1.5;
}

@media screen and (min-height : 800px) {
  .my-leaf{
    left: -250px;
    scale: 2.5;
  }

  .my-leaf2{
    right: -228px;
    scale: 2.5;
  }
}
